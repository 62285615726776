import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const AgentBox = ({ nodeClick, responses }) => {
  return (
    <Paper elevation={3} sx={{ p: 2, height: '100%', overflow: 'auto' }}>
      {nodeClick ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="h6">Agent {nodeClick.id}</Typography>
          <Typography variant="body1">Current Answer:</Typography>
          {responses[nodeClick.id] ? (
            <Typography variant="body2">
              {responses[nodeClick.id].response}
            </Typography>
          ) : (
            <Typography variant="body2">No response for this round</Typography>
          )}
        </Box>
      ) : (
        <Typography variant="body1">Click on a node to see agent info</Typography>
      )}
    </Paper>
  );
};

export default AgentBox;