export default function Footer() {
    return (
        <footer className="text-black text-center mt-6 mb-6">
            <div className="flex justify-center gap-8">
                <a className="hover:underline cursor-pointer" href="https://www.linkedin.com/in/alexandre-gournail/" target="_blank" rel="noreferrer">Alexandre Gournail</a>
                <a className="hover:underline cursor-pointer" href="https://www.linkedin.com/in/ciaran-regan-2b1100194/" target="_blank" rel="noreferrer">Ciaran Regan</a>
                <a className="hover:underline cursor-pointer" href="https://www.linkedin.com/in/bastienyoussfi/" target="_blank" rel="noreferrer">Bastien Youssfi</a>
                <a className="hover:underline cursor-pointer" href="https://www.linkedin.com/in/daniel-trundle-a061221a8/" target="_blank" rel="noreferrer">Daniel Trundle</a>
            </div>
        </footer>
    );
}