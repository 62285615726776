import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Grid,
  Divider,
} from '@mui/material';
import { 
  Help as HelpIcon, 
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`query-tabpanel-${index}`}
      aria-labelledby={`query-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ height: 180, overflow: 'auto' }}>{children}</Box>}
    </div>
  );
}

export default function QueryPanel({ open, onClose, onSubmit, templateList = [] }) {
  const [activeTab, setActiveTab] = useState(0);
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState(['', '', '', '']);
  const [graphTemplate, setGraphTemplate] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [customGraph, setCustomGraph] = useState(null);

  useEffect(() => {
    const validateForm = () => {
      const isQuestionValid = question.trim().length > 0;
      const areOptionsValid = activeTab === 1 || options.every(option => option.trim().length > 0);
      const isGraphSelected = graphTemplate !== '' || customGraph !== null;
      setIsValid(isQuestionValid && areOptionsValid && isGraphSelected);
    };
    validateForm();
  }, [question, options, activeTab, graphTemplate, customGraph]);

  const handleQuestionChange = (event) => setQuestion(event.target.value);

  const handleOptionChange = (index, event) => {
    const newOptions = [...options];
    newOptions[index] = event.target.value;
    setOptions(newOptions);
  };

  const handleSubmit = () => {
    onSubmit({ 
      question, 
      options: activeTab === 0 ? options : [], 
      isOpenEnded: activeTab === 1,
      graphTemplate,
      customGraph 
    });
    onClose();
  };

  const handleGraphTemplateChange = (event) => {
    setGraphTemplate(event.target.value);
    setCustomGraph(null); // Clear custom graph when template is selected
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);
          setCustomGraph(json);
          setGraphTemplate(''); // Clear template selection when custom graph is uploaded
        } catch (error) {
          console.error("Error parsing JSON:", error);
          // You might want to show an error message to the user here
        }
      };
      reader.readAsText(file);
    }
  };

  const handleClearCustomGraph = () => {
    setCustomGraph(null);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      fullWidth 
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: 3,
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, bgcolor: 'primary.main', color: 'white' }}>
        Query Designer
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column' }}>
        <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} centered>
          <Tab label="Multiple Choice" />
          <Tab label="Open-Ended" />
        </Tabs>

        <TextField
          fullWidth
          label="Enter your question"
          variant="outlined"
          value={question}
          onChange={handleQuestionChange}
          margin="normal"
          sx={{ mb: 2 }}
        />

        <TabPanel value={activeTab} index={0} sx={{mt: 2, zIndex: 10}}>
          <Grid container spacing={2}>
            {['A', 'B', 'C', 'D'].map((letter, index) => (
              <Grid item xs={6} key={letter}>
                <TextField
                  fullWidth
                  label={`Option ${letter}`}
                  variant="outlined"
                  value={options[index]}
                  onChange={(e) => handleOptionChange(index, e)}
                />
              </Grid>
            ))}
          </Grid>
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="body1" color="textSecondary" align="center">
              Participants will provide their own answers to this open-ended question.
            </Typography>
          </Box>
        </TabPanel>

        <Divider />

        <Box mt={2}>
          <Typography variant="subtitle1" gutterBottom>
            Graph Selection
          </Typography>
          <Box display="flex" alignItems="center">
            <FormControl fullWidth variant="outlined">
              <InputLabel>Graph templates</InputLabel>
              <Select
                value={graphTemplate}
                onChange={handleGraphTemplateChange}
                label="Graph templates"
                disabled={customGraph !== null}
              >
                {templateList && templateList.length > 0 ? (
                  templateList.map(({ id, beautifullLabel }) => (
                    <MenuItem key={id} value={id}>{beautifullLabel}</MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No templates available</MenuItem>
                )}
              </Select>
            </FormControl>
            <Tooltip title="Upload your own graph">
              <IconButton color="primary" component="label" sx={{ ml: 2 }}>
                <CloudUploadIcon />
                <input type="file" hidden onChange={handleFileUpload} accept=".json" />
              </IconButton>
            </Tooltip>
            {customGraph && (
              <Tooltip title="Clear custom graph">
                <IconButton color="secondary" onClick={handleClearCustomGraph} sx={{ ml: 1 }}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {customGraph && (
            <Typography variant="body2" color="textSecondary" mt={1}>
              Custom graph uploaded: {customGraph.nodes.length} nodes, {customGraph.links.length} links
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', px: 3, py: 2 }}>
        <Button 
          startIcon={<HelpIcon />}
          onClick={() => {/* Open help dialog */}}
        >
          Need Help?
        </Button>
        <Box>
          <Button onClick={onClose} sx={{ mr: 1 }}>Cancel</Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            disabled={!isValid}
          >
            Create Query
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}