import Layout from './Components/Layout';
import { Routes, Route } from "react-router-dom";
import Homepage from './Pages/Homepage';
import { UserContextProvider } from './UserContext';
import Simulation from './Pages/Simulation';
import { useEffect, useState } from 'react';

function App() {
  // eslint-disable-next-line
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await fetch('/api/get_user_id');
        const data = await response.json();
        setUserId(data.user_id);
        localStorage.setItem('user_id', data.user_id);
      } catch (error) {
        console.error('Error fetching user ID:', error);
      }
    };

    fetchUserId();
  }, []);

  return (
    <UserContextProvider>
      <Routes>
        <Route path="/" element= { <Layout /> }>
          <Route index element= { <Homepage /> } />
          <Route path="/simulation" element= { <Simulation /> } />
        </Route>
      </Routes>
    </UserContextProvider>
  );
}

export default App;