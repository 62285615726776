export default function parseAgentResponse(responses, isOpenQuestion) {
    const parsedResponses = {};

    if (isOpenQuestion) {
        // For open questions, just wrap the response in an object
        Object.keys(responses).forEach((agent) => {
            parsedResponses[agent] = { response: responses[agent] };
        });
    } else {
        Object.keys(responses).forEach((agent) => {
            parsedResponses[agent] = parseStringResponse(responses[agent]);
        });
    }
    return parsedResponses;
}

function parseStringResponse(response) {
    // Parse the string response for MMLU questions.
    // Return X if it can not find a response.
    const regex = /\([a-dA-D]\)/g;
    var parsed_response = response.match(regex)
    try {
        parsed_response = parsed_response[parsed_response.length-1][1]
    }
    catch {
        parsed_response = "X"

    }
    return {response: response, parsed_response: parsed_response}
}
