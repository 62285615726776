import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Graph, QueryPanel, NavigationButtons, SimulationHeader, InfoPanel } from "./../Components";
import { Box, Grid } from '@mui/material';
import templateList from "./../Templates/graphs_templates.json";
import { parseAgentResponse } from '../Utils';

export default function Simulation() {
    const initialGraphData = { nodes: [], links: [], responses: [] };
    const userId = localStorage.getItem('user_id');
    const [graphDataParam, setGraphDataParam] = useState(initialGraphData);
    const [question, setQuestion] = useState({"statement": "", "propositions": [ "", "", "", "" ]});
    const [nodeHover, setNodeHover] = useState(null);   
    const [nodeClick, setNodeClick] = useState(null);
    const [responses, setResponses] = useState({});
    const [numRound, setNumRound] = useState(0);
    const [containerSize, setContainerSize] = useState({ width: 400, height: 500 });
    const [experimentStarted, setExperimentStarted] = useState(false);
    const graphContainerRef = useRef(null);
    const [graphList, setGraphList] = useState([]);
    const [isOpenQuestion, setIsOpenQuestion] = useState(false);
    const [queryPanelOpen, setQueryPanelOpen] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [chats, setChats] = useState({});
    const [loading, setLoading] = useState(false);

    const handleOpenQueryPanel = () => {
        setQueryPanelOpen(true);
    };

    const handleCloseQueryPanel = () => {
        setQueryPanelOpen(false);
    };

    const handleNodeClick = (node) => {
        setNodeClick(node);
        setNodeHover(null);
        setTabValue(1); // Switch to the Agent tab (index 1)
    };

    const handleTabChange = useCallback((event, newValue) => {
        setTabValue(newValue);
      }, []);

    const handleQuerySubmit = async (queryData) => {
        const newQuestion = {
            statement: queryData.question,
            propositions: queryData.options
        };
        setQuestion(newQuestion);
        setIsOpenQuestion(queryData.isOpenEnded);
        setChats({});
        setLoading(true);
        let newGraphData;
    
        if (queryData.customGraph) {
            newGraphData = queryData.customGraph;
        } else {
            const selectedTemplate = templateList.find(template => template.id === queryData.graphTemplate);
            if (selectedTemplate) {
                newGraphData = {
                    nodes: selectedTemplate.graph.nodes,
                    links: selectedTemplate.graph.links,
                    responses: selectedTemplate.graph.responses
                };
            } else {
                console.error("No matching template found");
                setLoading(false);
                return;
            }
        }
    
        // Create bidirectional links
        const bidirectionalLinks = newGraphData.links.flatMap(link => [
            { ...link },
            { source: link.target, target: link.source }
        ]);
    
        newGraphData = {
            ...newGraphData,
            links: bidirectionalLinks
        };
    
        setGraphDataParam(newGraphData);
    
        try {
            const response = await fetch("/api/simulation/begin", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "graph": newGraphData,
                    "question": newQuestion,
                    "is_open_question": queryData.isOpenEnded,
                    "userId": userId
                }),
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            setNumRound(0);
            setExperimentStarted(true);
            setGraphList([newGraphData]);
            setResponses({});  // Clear previous responses
        } catch (error) {
            console.error("Error submitting question:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const updateContainerSize = () => {
            if (graphContainerRef.current) {
                setContainerSize({
                    width: graphContainerRef.current.clientWidth,
                    height: graphContainerRef.current.clientHeight
                });
            }
        };
        updateContainerSize();
        window.addEventListener('resize', updateContainerSize);
        return () => {
            window.removeEventListener('resize', updateContainerSize);
        };
    }, []);

    const resetGraph = (ev) => {
        ev.preventDefault();
        setLoading(true);
        fetch("/api/simulation", {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "userId" : userId }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(_ => {
                setGraphDataParam(initialGraphData);
                setNumRound(0);
                setExperimentStarted(false);
                setResponses({});
                setChats({});
                setGraphList([]);
                setQuestion({"statement": "", "propositions": [ "", "", "", "" ]});
                setIsOpenQuestion(false);
                setNodeClick(null);
                setNodeHover(null);
                setTabValue(0);
            })
            .catch(error => {
                console.error("Error resetting simulation:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    async function nextRound(ev) {
        ev.preventDefault();
    
        const nextRoundNumber = numRound + 1;
        
        if (nextRoundNumber < graphList.length) {
            // If we're moving to a round that already exists, just update the state
            setNumRound(nextRoundNumber);
            setResponses(graphList[nextRoundNumber].responses);
        } else {
            // If it's a new round, fetch new data
            setLoading(true);
            try {
                const response = await fetch("/api/simulation/nextround", {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        "numRound" : nextRoundNumber, 
                        "question" : question, 
                        "graphData" : graphList[graphList.length - 1],
                        "userId" : userId
                    }),
                });
                
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                
                const data = await response.json();
                const parsedResponses = parseAgentResponse(data.responses, isOpenQuestion);
                
                // Ensure bidirectional links
                const bidirectionalLinks = data.links.flatMap(link => [
                    { ...link },
                    { source: link.target, target: link.source }
                ]);
        
                const newGraph = {
                    nodes: data.nodes,
                    links: bidirectionalLinks,
                    responses: parsedResponses
                };
                
                setGraphList(prevList => [...prevList, newGraph]);
                setResponses(parsedResponses);
            } catch (error) {
                console.error("Error fetching next round data:", error);
            } finally {
                setLoading(false);
            }
        }
        
        setNumRound(nextRoundNumber);
        setExperimentStarted(true);
    }

    const previousRound = (ev) => {
        ev.preventDefault();
        if (numRound > 0) {
            const prevRound = numRound - 1;
            setNumRound(prevRound);
            setResponses(graphList[prevRound].responses);
        }
    };

    return (
        <Box sx={{ mx: 'auto', maxWidth: 1200, p: 3 }}>
            <QueryPanel
                open={queryPanelOpen}
                onClose={handleCloseQueryPanel}
                onSubmit={handleQuerySubmit}
                templateList={templateList}
            />

            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Box ref={graphContainerRef} sx={{ height: 650, backgroundColor: 'white', borderRadius: 2, boxShadow: 3 }}>
                    <Graph
                            graphData={graphDataParam}
                            responses={responses}
                            height={containerSize.height}
                            width={containerSize.width}
                            onNodeHover={(node) => setNodeHover(node)}
                            onNodeClick={handleNodeClick}
                            hoveredNode={nodeHover}
                            loading={loading}
                            numRound={numRound}
                        />
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <SimulationHeader 
                            numRound={numRound}
                            resetGraph={resetGraph}
                        />
                        <InfoPanel 
                            nodeClick={nodeClick}
                            responses={responses}
                            tabValue={tabValue}
                            handleTabChange={handleTabChange}
                            chats={chats}
                            setChats={setChats}
                            question={question}
                            mostRecentRound={numRound === graphList.length - 1}
                            isOpenQuestion={isOpenQuestion}
                            onOpenQueryPanel={handleOpenQueryPanel}
                            graphDataParam={graphDataParam}
                        />
                        <NavigationButtons 
                            numRound={numRound}
                            experimentStarted={experimentStarted}
                            previousRound={previousRound}
                            nextRound={nextRound}
                            loading={loading}
                            questionSet={question.statement !== ""}
                            maxRounds={6}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}