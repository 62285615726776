import React from 'react';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';

const SimulationHeader = ({ numRound, resetGraph }) => {
  return (
    <Box 
      sx={{ 
        backgroundColor: 'background.paper',
        borderRadius: 2,
        boxShadow: 3,
        p: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 2
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" component="span" sx={{ mr: 2 }}>
          Round
        </Typography>
        <Box 
          sx={{ 
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            borderRadius: '50%',
            width: 40,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1.2rem'
          }}
        >
          {numRound}
        </Box>
      </Box>
      <Box>
        <Tooltip title="Reset simulation">
          <IconButton 
            onClick={resetGraph}
            color="error"
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default SimulationHeader;