import React, { useState, useRef, useEffect } from 'react';
import { List, TextField, ListItem, Button, Box, Avatar, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import SmartToyIcon from '@mui/icons-material/SmartToy';

const TypingAnimation = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 30 }}>
    <CircularProgress size={20} />
  </Box>
);

function ChatBox({selectedNode, chats, setChats, mostRecentRound}) {
    const [chatMessage, setChatMessage] = useState('');
    const [isTyping, setIsTyping] = useState({});

    const chatListRef = useRef(null);

    useEffect(() => {
        if (chatListRef.current) {
            chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
        }
    }, [chats, selectedNode, isTyping]);

    const handleSendChat = () => {
        if (selectedNode && chatMessage.trim() !== '') {
            const newMessage = chatMessage.trim();
            setChats(prevChats => ({
                ...prevChats,
                [selectedNode.id]: [...(prevChats[selectedNode.id] || []), { type: 'user', content: newMessage }]
            }));
            setChatMessage('');
            setIsTyping(prev => ({ ...prev, [selectedNode.id]: true }));
            postChat(newMessage, selectedNode.id);
        }
    };

    const postChat = (message, agent_id) => {
        fetch("/api/simulation/chat", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "agent_id": agent_id, "chat": message}),
        })
        .then(response => response.json())
        .then(data => {
            setChats(prevChats => ({
                ...prevChats,
                [agent_id]: [...(prevChats[agent_id] || []), { type: 'agent', content: data.message }]
            }));
            setIsTyping(prev => ({ ...prev, [agent_id]: false }));
        })
        .catch(error => {
            console.error("Error sending chat message:", error);
            setIsTyping(prev => ({ ...prev, [agent_id]: false }));
        });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendChat();
        }
    };
    
    const currentChat = selectedNode ? (chats[selectedNode.id] || []) : [];
    const currentIsTyping = selectedNode ? isTyping[selectedNode.id] : false;

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            height: '100%', 
            width: '100%',
            overflow: 'hidden'
        }}>
            <Box sx={{
                flexGrow: 1,
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column'
            }} ref={chatListRef}>
                <List>
                    {currentChat.map((msg, index) => (
                        <ListItem 
                            key={index} 
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: msg.type === 'agent' ? 'flex-start' : 'flex-end',
                                alignItems: 'flex-end',
                                p: 0,
                                mb: 1,
                            }}
                        >
                            {msg.type === 'agent' && (
                                <Avatar 
                                    sx={{ 
                                        mr: 1, 
                                        bgcolor: 'primary.main', 
                                        width: 24, 
                                        height: 24,
                                        alignSelf: 'flex-end',
                                        mb: 0.5
                                    }}
                                >
                                    <SmartToyIcon sx={{ fontSize: 16 }} />
                                </Avatar>
                            )}
                            <Box
                                sx={{
                                    backgroundColor: msg.type === 'agent' ? '#f5f5f5' : '#e3f2fd',
                                    borderRadius: '20px',
                                    maxWidth: '70%',
                                    p: 2,
                                    wordBreak: 'break-word'
                                }}
                            >
                                {msg.content}
                            </Box>
                        </ListItem>
                    ))}
                    {currentIsTyping && (
                        <ListItem 
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-end',
                                p: 0,
                                mb: 1,
                            }}
                        >
                            <Avatar 
                                sx={{ 
                                    mr: 1, 
                                    bgcolor: 'primary.main', 
                                    width: 24, 
                                    height: 24,
                                    alignSelf: 'flex-end',
                                    mb: 0.5
                                }}
                            >
                                <SmartToyIcon sx={{ fontSize: 16 }} />
                            </Avatar>
                            <Box
                                sx={{
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '20px',
                                    p: 2,
                                }}
                            >
                                <TypingAnimation />
                            </Box>
                        </ListItem>
                    )}
                </List>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    fullWidth
                    value={chatMessage}
                    onChange={(e) => setChatMessage(e.target.value)}
                    onKeyDown={handleKeyPress}
                    placeholder="Type your message..."
                    size="small"
                    sx={{ mr: 1 }}
                />
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleSendChat} 
                    disabled={!selectedNode || !mostRecentRound} // disabled if not right node or not most recent round
                    sx={{ minWidth: 0, width: '40px', height: '40px', p: 0 }}
                >
                    <SendIcon />
                </Button>
            </Box>
        </Box>
    );
}

export default ChatBox;