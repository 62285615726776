import React, { useRef, useEffect } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import * as d3 from 'd3';

const COLORS = {
    A: '#4e79a7', // Steel Blue
    B: '#f28e2c', // Orange
    C: '#59a14f', // Green
    D: '#e15759', // Soft Red
    X: '#4d4d4d', // Teal
    default: '#4d4d4d' // Dark Gray (for default/unassigned)
};

const Graph = ({ graphData, responses, height, width, hoveredNode, onNodeHover, onNodeClick, loading, numRound }) => {
    const fgRef = useRef();

    useEffect(() => {
        if (fgRef.current) {
            fgRef.current.d3Force('charge', d3.forceManyBody().strength(-500));
            // Delay zoomToFit to ensure the graph is rendered before fitting
            setTimeout(() => fgRef.current.zoomToFit(300), 3000);
        }
    }, [graphData]);

    const resetZoom = () => {
        if (fgRef.current) {
            fgRef.current.zoomToFit(300); // Adjusting the duration to make it smoother
        }
    };

    const nodeColor = (node) => {
        try {
            return COLORS[responses[node.id].parsed_response] || COLORS['default'];
        }
        catch (error) {
            return COLORS['default'];
        }
    }

    const particleColor = (link) => {
        return nodeColor(link.source);
    }

    return (
        <div style={{ 
            width: '100%', 
            height: '100%', 
            position: 'relative',
            overflow: 'hidden' // This will prevent any overflow
        }}>
            <ForceGraph2D
                ref={fgRef}
                graphData={graphData}
                nodeColor={nodeColor}
                width={width}
                height={height}
                minZoom={0.5}
                onNodeHover={onNodeHover}
                nodeCanvasObject={(node, ctx, globalScale) => {
                    const label = node.id;
                    const fontSize = 32 / globalScale;
                    ctx.font = `${fontSize}px Sans-Serif`;
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';

                    const nodeRadius = 5;
                    const color = nodeColor(node);

                    // Draw node circle
                    ctx.beginPath();
                    ctx.arc(node.x, node.y, nodeRadius, 0, 2 * Math.PI, false);
                    ctx.fillStyle = color;
                    ctx.fill();

                    // Draw outline if hovered
                    if (node === hoveredNode) {
                        ctx.strokeStyle = 'black';
                        ctx.lineWidth = 1;
                        ctx.stroke();

                        // Display label
                        ctx.fillStyle = 'black';
                        ctx.fillText(label, node.x, node.y - nodeRadius - 5);
                    }
                }}
                nodePointerAreaPaint={(node, color, ctx) => {
                    ctx.beginPath();
                    ctx.arc(node.x, node.y, 5, 0, 2 * Math.PI, false);
                    ctx.fillStyle = color;
                    ctx.fill();
                }}
                onNodeClick={node => {
                    if (fgRef.current) {
                        fgRef.current.centerAt(node.x, node.y, 1000);
                        fgRef.current.zoom(8, 500);
                    }
                    onNodeClick(node);
                }}
                linkWidth={1}
                linkDirectionalParticles={(link) => {
                    if (loading && numRound >= 1) {
                        return 3;  // Number of particles
                    } else {
                        return 0;  // No particles
                    }
                }}
                linkDirectionalParticleSpeed={0.005}  // Speed of particles
                linkDirectionalParticleWidth={2}
                linkDirectionalParticleColor={particleColor}
            />
            <button 
                onClick={resetZoom} 
                style={{ 
                    position: 'absolute', 
                    top: '10px', 
                    right: '10px',
                    zIndex: 1000,
                    padding: '5px 10px',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    cursor: 'pointer'
                }}
            >
                Reset Zoom
            </button>
        </div>
    );
};

export default Graph;