// eslint-disable-next-line
import React, { useState, useEffect } from 'react'

export default function Homepage() {
  const userId = localStorage.getItem('user_id');

  return (
    <div className="flex justify-evenly"> 
      <a className="bg-gray-200 rounded p-2 hover:bg-gray-300 transition-all duration-500" href="/simulation">Get started {userId}!</a>
    </div>
  )
}