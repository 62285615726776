import React, { useState, useEffect } from 'react';
import { Box, Typography, Tabs, Tab, List, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AgentBox from './AgentBox';
import ChatBox from './ChatBox';

const COLORS = {
    A: '#4e79a7', // Steel Blue
    B: '#f28e2c', // Orange
    C: '#59a14f', // Green
    D: '#e15759', // Soft Red
    X: '#4d4d4d', // Teal (for N/A)
    default: '#4d4d4d' // Dark Gray (for default/unassigned)
};

const InfoPanel = ({ 
  nodeClick, 
  responses, 
  tabValue, 
  handleTabChange,
  chats,
  setChats,
  question,
  mostRecentRound,
  isOpenQuestion,
  onOpenQueryPanel,
  graphDataParam
}) => {
  const [subTabValue, setSubTabValue] = useState(0);

  useEffect(() => {
    if (nodeClick) {
      handleTabChange(null, 1);
    }
  }, [nodeClick, handleTabChange]);

  const handleSubTabChange = (event, newValue) => {
    setSubTabValue(newValue);
  };

  const isQuestionAsked = question && question.statement;

  // Count responses for each option (including N/A)
  const responseCounts = React.useMemo(() => {
    const counts = { A: 0, B: 0, C: 0, D: 0, X: 0 };
    const totalAgents = graphDataParam.nodes.length;

    if (responses) {
      Object.values(responses).forEach(response => {
        const parsedResponse = response.parsed_response;
        if (['A', 'B', 'C', 'D'].includes(parsedResponse)) {
          counts[parsedResponse]++;
        } else {
          counts.X++;
        }
      });
    }

    // If responses object is empty or doesn't exist, all agents are N/A
    if (!responses || Object.keys(responses).length === 0) {
      counts.X = totalAgents;
    } else {
      // Add any agents not in the responses object to N/A
      counts.X += totalAgents - Object.keys(responses).length;
    }

    return counts;
  }, [responses, graphDataParam.nodes.length]);

  return (
    <Box sx={{ 
      flexGrow: 1, 
      backgroundColor: 'white', 
      borderRadius: 2, 
      boxShadow: 3, 
      display: 'flex', 
      flexDirection: 'column',
      height: '450px',
      overflow: 'hidden'
    }}>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Collective" />
        <Tab label="Individual" />
      </Tabs>
      <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
        {tabValue === 0 ? (
          isQuestionAsked ? (
            <Box>
              <Typography variant="h6" gutterBottom>Question:</Typography>
              <Typography variant="body1" paragraph>{question.statement}</Typography>
              {!isOpenQuestion && (
                <>
                  <Typography variant="h6" gutterBottom>Options:</Typography>
                  <List>
                    {['A', 'B', 'C', 'D', 'X'].map((option) => (
                      <ListItem key={option} sx={{ py: 0.5 }}>
                        <ListItemIcon sx={{ minWidth: 40 }}>
                          <FiberManualRecordIcon sx={{ color: COLORS[option] }} />
                        </ListItemIcon>
                        <ListItemText 
                          primary={
                            <Typography variant="body1">
                              {option === 'X' ? 'N/A' : question.propositions[['A', 'B', 'C', 'D'].indexOf(option)]}
                              <Typography component="span" variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                                ({responseCounts[option]})
                              </Typography>
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
              {isOpenQuestion && (
                <Typography variant="body1" sx={{ mt: 2 }}>
                  This is an open-ended question. Agents will provide their own unique responses.
                </Typography>
              )}
            </Box>
          ) : (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center',
              height: '100%'
            }}>
              <HelpOutlineIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
              <Typography variant="h6" gutterBottom align="center">
                No question asked yet
              </Typography>
              <Typography variant="body1" paragraph align="center">
                Start by designing a new query to begin the simulation.
              </Typography>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={onOpenQueryPanel}
              >
                Design New Query
              </Button>
            </Box>
          )
        ) : (
          nodeClick ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Tabs value={subTabValue} onChange={handleSubTabChange} sx={{ mb: 2 }}>
                <Tab label="Info" />
                <Tab label="Chat" />
              </Tabs>
              <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                {subTabValue === 0 ? (
                  <AgentBox nodeClick={nodeClick} responses={responses} isOpenQuestion={isOpenQuestion} />
                ) : (
                  <ChatBox 
                    selectedNode={nodeClick} 
                    chats={chats} 
                    setChats={setChats} 
                    mostRecentRound={mostRecentRound} 
                  />
                )}
              </Box>
            </Box>
          ) : (
            <Typography variant="body1">
              Select a node to view agent information and chat.
            </Typography>
          )
        )}
      </Box>
    </Box>
  );
};

export default InfoPanel;